import styled from 'styled-components';

import Container from 'components/_shared/Container';
import Heading from 'components/_shared/Heading/Heading';
import Paragraph from 'components/_shared/Paragraph/Paragraph';
import Link from 'components/_shared/PathLink/PathLink';
import { colors, media } from 'utils/variables';

export const StyledContainer = styled(Container)`
  height: calc(100vh - 200px) !important;
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  @media (min-width: ${media.s}) {
    padding-top: 200px;
    height: 100vh !important;
    min-height: 550px;
  }
  @media (min-width: ${media.m}) {
    padding-top: 300px;
  }
`;

export const StyledHeading = styled(Heading)`
  text-align: center;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

export const StyledLink = styled(Link)`
  color: ${colors.secondary};
`;
