import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/common/Layout';
import Seo from 'components/common/SEO';
import ErrorPage from 'components/ErrorPage';

const NotFoundPage = ({ data }) => {
  const { header, footer } = data.homepage.data;

  return (
    <Layout headerDarkMode footer={footer} header={header}>
      <ErrorPage />
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        footer: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
        header: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
};

export const Head = ({ location }) => {
  const metatags = { title: '404 Page' };
  return <Seo metatags={metatags} location={location} />;
};

export const query = graphql`
  query NotFoundPageQuery {
    homepage: prismicHomepage {
      data {
        header {
          document {
            ...Header
          }
        }
        footer {
          document {
            ...Footer
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
