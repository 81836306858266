import React from 'react';

import {
  StyledContainer,
  StyledHeading,
  StyledLink,
  StyledParagraph,
} from './styles';

const ErrorPage = () => (
  <StyledContainer>
    <StyledHeading type="h2">We can't find this page.</StyledHeading>
    <StyledParagraph>
      Please go back to our
      <StyledLink label=" homepage" href="/" />.
    </StyledParagraph>
  </StyledContainer>
);

export default ErrorPage;
